/**
 * Service for sending analytics data to analytics tools
 * like Google Tag Manager, Google Analytics, Elastic Stack etc...
 */
import { UxfPageContext } from "@app-routes";
import { config } from "@config/config";
import { CampaignFragment } from "@gql-schema";
import { normalizePhoneNumber } from "@shared/utils/normalize-phone-number";
import { Cookie } from "@uxf/core/cookie";
import axios from "axios";
import sha256 from "crypto-js/sha256";
import dayjs from "dayjs";
import merge from "lodash/merge";
import { GetServerSidePropsContext } from "next";
import { v4 as uuid } from "uuid";

export interface EventData {
    event?: string;
    eventCategory?: string;
    eventAction?: string;
    eventLabel?: string;
    eventValue?: string | number;
}

type GtmEventData = EventData;
type ElkEventData = EventData;

export interface AnalyticsEventProps {
    analyticsEventCategory?: string;
    analyticsEventAction?: string;
    analyticsEventLabel?: string;
    analyticsEventValue?: string;
}

interface GtmDataLayer {
    push: (gtmEventData: GtmEventData) => void;
}

declare global {
    interface Window {
        dataLayer: GtmDataLayer;
    }
}

type DeepPartial<T> = T extends object
    ? {
          [P in keyof T]?: DeepPartial<T[P]>;
      }
    : T;

const ANALYTICS_DATA_COOKIE_TTL = 3600 * 24 * 90;
const ANALYTICS_SESSION_ID_COOKIE_TTL = 3600;

const CommonDataKeysConst = [
    "accountUuid",
    "profileUuid",
    "commodity",
    "currentProvider",
    "buildingType",
    "userType",
    "maxSavingVisible",
    "maxVisibleSavingProvider",
    "maxVisibleSavingTariff",
    "maxSaving",
    "maxSavingProvider",
    "maxSavingTariff",
    "offersOpen",
    "bonityScore",
    "address",
    "yearlyPayment",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
    "deviceType",
    "screenDimensions",
    "landingURL",
    "URL",
    "sessionID",
    "dateTime",
    "abTestingVariant",
    "marketingProfile",
    "magicLinkLogin",
    "profileLinkLogin",
    "busVertical",
    "userRole",
    "vehicleType",
    "insuranceAmount",
    "email",
    "encryptedEmail",
    "phone",
    "encryptedPhone",
    // verticals
    "ene",
    "fin",
    "telco",
    "poj",
] as const;

type CommonDataKeys = (typeof CommonDataKeysConst)[number];

type EnergoDataKeys = keyof CommonData["ene"];
type FinanceDataKeys = keyof CommonData["fin"];
type TelcoDataKeys = keyof CommonData["telco"];
type VehicleInsuranceDataKeys = keyof CommonData["poj"]["pov"];
type PropertyInsuranceDataKeys = keyof CommonData["poj"]["maj"];
type TravelInsuranceDataKeys = keyof CommonData["poj"]["ces"];

interface CommonData {
    accountUuid: string;
    profileUuid: string;
    commodity: string;
    currentProvider: string;
    buildingType: string;
    userType: string;
    maxSavingVisible: string;
    maxVisibleSavingProvider: string;
    maxVisibleSavingTariff: string;
    maxSaving: string;
    maxSavingProvider: string;
    maxSavingTariff: string;
    offersOpen: string;
    bonityScore: string;
    address: string;
    yearlyPayment: string;
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
    utm_term: string;
    utm_content: string;
    deviceType: string;
    screenDimensions: string;
    landingURL: string;
    URL: string;
    sessionID: string;
    dateTime: string;
    abTestingVariant: string;
    marketingProfile: string;
    magicLinkLogin: boolean;
    profileLinkLogin: boolean;
    busVertical: string;
    userRole?: "BackOffice" | "User" | "Signed_User" | "Signed_User_BO";
    vehicleType: string;
    insuranceAmount: string;
    email: string;
    encryptedEmail: string;
    phone: string;
    encryptedPhone: string;
    ene: {
        calculationType: string;
        calculationUuid: string;
        monthlyAdvances: number;
        yearlyConsumptionKwh: number;
    };
    poj: {
        pov: {
            calculationUuid: string;
        };
        maj: {
            calculationUuid: string;
        };
        ces: {
            calculationUuid: string;
        };
    };
    fin: {
        calculationUuid: string;
    };
    telco: {
        calculationUuid: string;
    };
}

interface UtmParameters {
    [key: string]: string;
}

const gtmEvent = (gtmEventData: GtmEventData) => {
    if (typeof window.dataLayer !== "undefined") {
        window.dataLayer.push(gtmEventData);
    }
};

const elkEvent = (elkEventData: ElkEventData) => {
    const elkEnabled = config.ELK_ANALYTICS_ENABLED;

    elkEventData = merge(elkEventData, { env: config.STAGE });

    if (elkEnabled) {
        axios.post("/elk-analytics", elkEventData).catch(() => {
            return null;
        });
    }
};

const getAnalyticsData = (ctx?: UxfPageContext<any> | GetServerSidePropsContext): DeepPartial<CommonData> => {
    const cookie = new Cookie(ctx);
    const cookieString = cookie.get("analyticsCommonData") || "{}";

    if (cookieString !== "{}") {
        return JSON.parse(cookieString);
    }

    return {};
};

const setAnalyticsCookie = (data: DeepPartial<CommonData>, ctx?: any) => {
    const cookie = new Cookie(ctx);
    cookie.set("analyticsCommonData", JSON.stringify(data), ANALYTICS_DATA_COOKIE_TTL);
};

const setAnalyticsData = <T extends CommonDataKeys>(
    name: T,
    value: CommonData[T],
    ctx?: UxfPageContext<any> | GetServerSidePropsContext,
): void => {
    const analyticsCommonData = getAnalyticsData(ctx);

    analyticsCommonData[name] = value;

    setAnalyticsCookie(analyticsCommonData, ctx);
};

const setEnergoValue = <T extends EnergoDataKeys>(
    name: T,
    value: CommonData["ene"][T],
    ctx?: UxfPageContext<any> | GetServerSidePropsContext,
): void => {
    const analyticsCommonData = getAnalyticsData(ctx);

    analyticsCommonData.ene = analyticsCommonData.ene || {};
    analyticsCommonData.ene[name] = value;

    setAnalyticsCookie(analyticsCommonData, ctx);
};

const setTelcoValue = <T extends TelcoDataKeys>(
    name: T,
    value: CommonData["telco"][T],
    ctx?: UxfPageContext<any> | GetServerSidePropsContext,
): void => {
    const analyticsCommonData = getAnalyticsData(ctx);

    analyticsCommonData.telco = analyticsCommonData.telco || {};
    analyticsCommonData.telco[name] = value;

    setAnalyticsCookie(analyticsCommonData, ctx);
};

const setFinanceValue = <T extends FinanceDataKeys>(
    name: T,
    value: CommonData["fin"][T],
    ctx?: UxfPageContext<any> | GetServerSidePropsContext,
): void => {
    const analyticsCommonData = getAnalyticsData(ctx);

    analyticsCommonData.fin = analyticsCommonData.fin || {};
    analyticsCommonData.fin[name] = value;

    setAnalyticsCookie(analyticsCommonData, ctx);
};

const setPojPovValue = <T extends VehicleInsuranceDataKeys>(
    name: T,
    value: CommonData["poj"]["pov"][T],
    ctx?: UxfPageContext<any> | GetServerSidePropsContext,
): void => {
    const analyticsCommonData = getAnalyticsData(ctx);

    analyticsCommonData.poj = analyticsCommonData.poj || {};
    analyticsCommonData.poj.pov = analyticsCommonData.poj.pov || {};
    analyticsCommonData.poj.pov[name] = value;

    setAnalyticsCookie(analyticsCommonData, ctx);
};

const setPojMajValue = <T extends PropertyInsuranceDataKeys>(
    name: T,
    value: CommonData["poj"]["maj"][T],
    ctx?: UxfPageContext<any> | GetServerSidePropsContext,
): void => {
    const analyticsCommonData = getAnalyticsData(ctx);

    analyticsCommonData.poj = analyticsCommonData.poj || {};
    analyticsCommonData.poj.maj = analyticsCommonData.poj.maj || {};
    analyticsCommonData.poj.maj[name] = value;

    setAnalyticsCookie(analyticsCommonData, ctx);
};

const setPojCesValue = <T extends TravelInsuranceDataKeys>(
    name: T,
    value: CommonData["poj"]["ces"][T],
    ctx?: UxfPageContext<any> | GetServerSidePropsContext,
): void => {
    const analyticsCommonData = getAnalyticsData(ctx);

    analyticsCommonData.poj = analyticsCommonData.poj || {};
    analyticsCommonData.poj.ces = analyticsCommonData.poj.ces || {};
    analyticsCommonData.poj.ces[name] = value;

    setAnalyticsCookie(analyticsCommonData, ctx);
};

const setBulkAnalyticsData = (
    data: DeepPartial<Record<CommonDataKeys, any>>,
    ctx?: UxfPageContext<any> | GetServerSidePropsContext,
): void => {
    const analyticsCommonData = getAnalyticsData(ctx);

    const analyticsData = merge(analyticsCommonData, data);

    setAnalyticsCookie(analyticsData, ctx);
};

const refreshAnalyticsSessionId = (): string => {
    const cookie = new Cookie();
    const value = cookie.get("Analytics-Session-Id") || uuid();

    cookie.set("Analytics-Session-Id", value, ANALYTICS_SESSION_ID_COOKIE_TTL);

    return value;
};

const setUtmParameters = (
    value: string | Record<string, any>,
    ctx?: UxfPageContext<any> | GetServerSidePropsContext,
): void => {
    let pairs: Partial<UtmParameters> = {};
    if (typeof value === "string") {
        const string = value.substring(1);
        string.split("&").map((param) => {
            const [type, val] = param.split("=");
            pairs[type] = val;
        });
    } else {
        pairs = value;
    }

    setBulkAnalyticsData(
        {
            utm_source: pairs.utm_source ?? null,
            utm_medium: pairs.utm_medium ?? null,
            utm_campaign: pairs.utm_campaign ?? null,
            utm_term: pairs.utm_term ?? null,
            utm_content: pairs.utm_content ?? null,
        },
        ctx,
    );
};

const setDeviceType = (value: string, ctx?: UxfPageContext<any>): void => {
    setAnalyticsData("deviceType", value, ctx);
};

const setScreenDimensions = (width: number, height: number): void => {
    setAnalyticsData("screenDimensions", `${width}x${height}`);
};

const setLandingURL = (value: string): void => {
    const cookie = new Cookie();
    // prevent change for the same sessionId
    if (cookie.get("Analytics-Session-Id")) {
        return;
    }

    setAnalyticsData("landingURL", value);
};

const setProfileUuid = (value: string): void => {
    setAnalyticsData("profileUuid", value);
};

const setAccountUuid = (value: string): void => {
    setAnalyticsData("accountUuid", value);
};

const setURL = (value: string): void => {
    setAnalyticsData("URL", value);
};

const setSessionId = (value: string): void => {
    setAnalyticsData("sessionID", value);
};

const setDateTime = (value: string): void => {
    setAnalyticsData("dateTime", value);
};

const setCommodity = (value: string): void => {
    setAnalyticsData("commodity", value);
};

const setCurrentProvider = (value: string): void => {
    setAnalyticsData("currentProvider", value);
};

const setBuildingType = (value: string): void => {
    setAnalyticsData("buildingType", value);
};

const setUserType = (value: string): void => {
    setAnalyticsData("userType", value);
};

const setMaxSavingVisible = (value: string): void => {
    setAnalyticsData("maxSavingVisible", value);
};

const setMaxVisibleSavingProvider = (value: string): void => {
    setAnalyticsData("maxVisibleSavingProvider", value);
};

const setMaxVisibleSavingTariff = (value: string): void => {
    setAnalyticsData("maxVisibleSavingTariff", value);
};

const setMaxSaving = (value: string): void => {
    setAnalyticsData("maxSaving", value);
};

const setMaxSavingProvider = (value: string): void => {
    setAnalyticsData("maxSavingProvider", value);
};

const setMaxSavingTariff = (value: string): void => {
    setAnalyticsData("maxSavingTariff", value);
};

const setOffersOpen = (value: string): void => {
    setAnalyticsData("offersOpen", value);
};

const setBonityScore = (value: string): void => {
    setAnalyticsData("bonityScore", value);
};

const setYearlyPayment = (value: string): void => {
    setAnalyticsData("yearlyPayment", value);
};

const setAddress = (value: string): void => {
    setAnalyticsData("address", value);
};

const setBusVertical = (value: string): void => {
    setAnalyticsData("busVertical", value);
};

const setUserRole = (value: CommonData["userRole"]): void => {
    setAnalyticsData("userRole", value);
};

const setMagicLinkLogin = (value: CommonData["magicLinkLogin"], ctx?: UxfPageContext<any>): void => {
    setAnalyticsData("magicLinkLogin", value, ctx);
};

const setProfileLinkLogin = (value: CommonData["profileLinkLogin"], ctx?: UxfPageContext<any>): void => {
    setAnalyticsData("profileLinkLogin", value, ctx);
};

const setAbTestingVariant = (value: CommonData["abTestingVariant"], ctx?: UxfPageContext<any>): void => {
    setAnalyticsData("abTestingVariant", value, ctx);
};

const setMarketingProfile = (value: CampaignFragment[]): void => {
    setAnalyticsData("marketingProfile", JSON.stringify(value.map((c) => c.name)));
};

const setVehicleType = (value: CommonData["vehicleType"]): void => {
    setAnalyticsData("vehicleType", value);
};

// vehicle insurance contract amount
const setInsuranceAmount = (value: CommonData["insuranceAmount"]): void => {
    setAnalyticsData("insuranceAmount", value);
};

const setEmail = (value: CommonData["email"]): void => {
    const normalizedValue = value.toLowerCase();

    setAnalyticsData("email", normalizedValue);
    setAnalyticsData("encryptedEmail", sha256(normalizedValue).toString());
};

const setPhone = (value: CommonData["phone"]): void => {
    const normalizedValue = normalizePhoneNumber(value, "420");

    setAnalyticsData("phone", normalizedValue);
    setAnalyticsData("encryptedPhone", sha256(normalizedValue).toString());
};

// getters
const getDeviceType = (ctx?: UxfPageContext<any>): string | null => {
    return getAnalyticsData(ctx).deviceType || null;
};

const getUserRole = (ctx?: UxfPageContext<any>): string | null => {
    return getAnalyticsData(ctx).userRole || null;
};

const getBusVertical = (ctx?: UxfPageContext<any>): string | null => {
    return getAnalyticsData(ctx).busVertical || null;
};

const getLandingUrl = (ctx?: UxfPageContext<any>): string | null => {
    return getAnalyticsData(ctx).landingURL || null;
};

const getUtmParameters = (
    ctx?: UxfPageContext<any>,
): Record<"utmCampaign" | "utmContent" | "utmMedium" | "utmSource", string | null> => {
    const cookies = getAnalyticsData(ctx);

    return {
        utmCampaign: cookies.utm_campaign || null,
        utmContent: cookies.utm_content || null,
        utmMedium: cookies.utm_medium || null,
        utmSource: cookies.utm_source || null,
    };
};

const event = (eventData: EventData): void => {
    if (eventData.eventAction) {
        setURL(decodeURI(window.location.href));
        const cookie = new Cookie();
        setProfileUuid(cookie.get("Profile-Uuid"));
        setSessionId(refreshAnalyticsSessionId());
        setDateTime(dayjs().format("YYYY-MM-DD HH:mm:ss"));
        const commonData = getAnalyticsData();

        const data: EventData = { ...eventData };
        Object.keys(commonData)
            .filter((key) => CommonDataKeysConst.includes(key as any))
            .reduce(
                (res, key) =>
                    (
                        // TODO: typy!
                        ((data[key as unknown as keyof EventData] as unknown) = commonData[key as CommonDataKeys]), res
                    ),
                {},
            );

        if (config.STAGE !== "prod") {
            // eslint-disable-next-line no-console
            console.log("🔎 DEBUG ANALYTICS EVENT DATA: ", data);
        }
        gtmEvent(data);

        elkEvent(data);
    } else {
        if (config.STAGE !== "prod") {
            // eslint-disable-next-line no-console
            console.log("🔎❌ DEBUG ANALYTICS - NOT LOGGED");
        }
    }
};

const formEvent = (category: string, action: string, label: string, value?: string | number) => (): void => {
    event({
        event: "InteractionForm",
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
    });
};

const uiEvent = (category: string, action: string, label: string, value?: string | number) => (): void => {
    event({
        event: "InteractionUI",
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
    });
};

export const AnalyticsService = {
    event,
    formEvent,
    getBusVertical,
    getDeviceType,
    getLandingUrl,
    getUserRole,
    getUtmParameters,
    setAbTestingVariant,
    setAccountUuid,
    setAddress,
    setAnalyticsData,
    setBonityScore,
    setBuildingType,
    setBusVertical,
    setCommodity,
    setCurrentProvider,
    setDateTime,
    setDeviceType,
    setEmail,
    setInsuranceAmount,
    setLandingURL,
    setMagicLinkLogin,
    setMarketingProfile,
    setMaxSaving,
    setMaxSavingProvider,
    setMaxSavingTariff,
    setMaxSavingVisible,
    setMaxVisibleSavingProvider,
    setMaxVisibleSavingTariff,
    setOffersOpen,
    setPhone,
    setProfileLinkLogin,
    setProfileUuid,
    setScreenDimensions,
    setSessionId,
    setURL,
    setUserRole,
    setUserType,
    setUtmParameters,
    setVehicleType,
    setYearlyPayment,
    uiEvent,
    // verticals
    setEnergoValue,
    setFinanceValue,
    setPojCesValue,
    setPojMajValue,
    setPojPovValue,
    setTelcoValue,
};
